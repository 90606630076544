import * as React from 'react';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router';
import { CheckBox, Dropdown } from 'marvin-ui-kit';
import { useInput } from 'react-hanger';
import { LatLngBounds } from 'leaflet';
import { xor, without, orderBy } from 'lodash-es';
// @ts-ignore
import Tooltip from '@zeit-ui/react/esm/tooltip';

import { RootState, Layer, Pollutant, APPLICATION_SCOPE } from '../../types';
import {
  setActiveOverlayers,
  setActivePeriods,
  setBoundarySelection,
  setActiveSectors,
} from './../../redux/selection/actions';
import {
  getPeriods,
  getOverlayers,
  getActivePollutant,
  getBounds,
  getBoundarySelection,
  getActiveOverlayers,
  getActivePeriods,
  getApplicationScope,
  getSectors,
  getActiveSectors,
} from './../../selectors/index';
import Icon from '../../components/icon';
import { ActiveLayers } from '../../components/active-layers';
import {
  PeriodSelectorToggle,
  PeriodSelectorExpandable,
} from '../../components/period-selector';
import { filterLayers } from './../../utils/filter.utils';
import {
  SectorSelectorToggle,
  SectorSelectorExpandable,
} from '../../components/sector-selector';
import styles from './styles.module.scss';

interface OwnProps {}

interface StateProps {
  scope: APPLICATION_SCOPE | null;
  layers: Layer[];
  activeLayers: Layer[];
  activePollutant: Pollutant;
  periods: string[];
  selectedPeriods: string[];
  sectors: string[];
  selectedSectors: string[] | null;
  bounds: LatLngBounds | null;
  boundarySelection: LatLngBounds | null;
}
interface DispatchProps {
  setActiveOverlayers: typeof setActiveOverlayers;
  setActivePeriods: typeof setActivePeriods;
  setActiveSectors: typeof setActiveSectors;
  setBoundarySelection: typeof setBoundarySelection;
}
type Props = OwnProps & StateProps & DispatchProps & RouteProps;

const LayersContainer = (props: Props) => {
  const searchQuery = useInput('');

  const filteredLayers = filterLayers(
    props.layers,
    props.activePollutant,
    props.selectedPeriods,
    props.selectedSectors,
    props.boundarySelection,
    searchQuery.value
  );

  const sortedLayers = orderBy(filteredLayers, 'name', 'asc');

  const handleBoundaryFilterClick = () => {
    if (props.bounds) {
      props.setBoundarySelection(props.bounds);
    }
  };

  return render();

  function render() {
    return (
      <div className={styles.container}>
        <div className={styles.filterGroup}>
          <div className={styles.filterItem}>
            <label htmlFor="searchQuery">Search by name</label>
            <div className={styles.searchInput}>
              <input
                value={searchQuery.value}
                onChange={searchQuery.onChange}
                type="search"
                placeholder="type here"
                id="searchQuery"
              />
              <Icon name="search" fill="rgba(255, 226, 90, 1)" size={12} />
            </div>
          </div>

          <div className={styles.filterItem}>
            <label>Year</label>

            <Dropdown
              toggle={<PeriodSelectorToggle />}
              expandableArea={
                <PeriodSelectorExpandable
                  periods={props.periods}
                  selectedPeriods={props.selectedPeriods}
                  onToggleAll={(toggleAll: boolean) => {
                    if (toggleAll) {
                      props.setActivePeriods(props.periods);
                    } else {
                      props.setActivePeriods([]);
                    }
                  }}
                  onTogglePeriod={period =>
                    props.setActivePeriods(xor(props.selectedPeriods, [period]))
                  }
                />
              }
            />
          </div>

          {props.scope === APPLICATION_SCOPE.emission && (
            //  Only filter on sector when in emissions scope
            <div className={styles.filterItem}>
              <label>Sector</label>

              <Dropdown
                toggle={<SectorSelectorToggle />}
                expandableArea={
                  <SectorSelectorExpandable
                    sectors={props.sectors}
                    selectedSectors={props.selectedSectors || []}
                    onToggleAll={(toggleAll: boolean) => {
                      if (toggleAll) {
                        props.setActiveSectors(props.sectors);
                      } else {
                        props.setActiveSectors([]);
                      }
                    }}
                    onToggleSector={sector =>
                      props.setActiveSectors(
                        xor(props.selectedSectors, [sector])
                      )
                    }
                  />
                }
              />
            </div>
          )}

          <div
            className={`${styles.filterItem} ${styles.boundaryFilterAction}`}
            onClick={handleBoundaryFilterClick}
          >
            <Icon name="sync-alt" size={13} fill="#FFE25A" />
            Filter layer list to map boundaries
          </div>
        </div>

        <div className={styles.layerList}>
          {sortedLayers.map((layer, i) => {
            return (
              <div
                className={styles.layerItem}
                key={i}
                onClick={() =>
                  props.setActiveOverlayers(xor(props.activeLayers, [layer]))
                }
              >
                <CheckBox
                  status={layer.isActive ? 'checked' : 'unchecked'}
                  checkedState={<Icon name="check-square" fill="#359EA6" />}
                />
                <span className={styles.layerItemLabel}>
                  {layer.name}

                  <Tooltip
                    text={'Push to Git and your website is live.'}
                  ></Tooltip>
                  {layer.disclaimer !== '' && (
                    <Tooltip text={layer.disclaimer} placement="right">
                      <Icon name="info-circle" size={15} fill="#349ea6" />
                    </Tooltip>
                  )}
                </span>
              </div>
            );
          })}
        </div>

        {props.activeLayers.length > 0 && (
          <ActiveLayers
            layers={props.activeLayers}
            onDelete={layer =>
              props.setActiveOverlayers(without(props.activeLayers, layer))
            }
          />
        )}
      </div>
    );
  }
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    scope: getApplicationScope(state),
    activePollutant: getActivePollutant(state),
    activeLayers: getActiveOverlayers(state),
    layers: getOverlayers(state),
    periods: getPeriods(state),
    selectedPeriods: getActivePeriods(state),
    sectors: getSectors(state),
    selectedSectors: getActiveSectors(state),
    bounds: getBounds(state),
    boundarySelection: getBoundarySelection(state),
  };
};
const mapDispatchToProps: DispatchProps = {
  setActiveOverlayers,
  setActivePeriods,
  setActiveSectors,
  setBoundarySelection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayersContainer);
