/* eslint import/no-webpack-loader-syntax: off */
import Papa from 'papaparse';
import { find, isEmpty } from 'lodash-es';
import { Layer } from '../types';
import * as GuppyService from './guppy.service';
import layersCsv from '!raw-loader!../assets/guppy_eu-comp-map_layers.csv';

interface CsvData {
  data: Array<{
    type: string;
    category: string;
    description: string;
    disclaimer: string;
    epsg_code: string;
    file: string;
    metadataUrl: string;
    pollutant: string;
    raster_name: string;
    resample: string;
    sector: string;
    tile_size: string;
    version: string;
    viewer_name: string;
  }>;
}

export async function getOverlayers(): Promise<Layer[]> {
  const rawData: CsvData = await new Promise<any>(resolve => {
    Papa.parse(layersCsv, {
      header: true,
      complete: results => resolve(results),
    });
  });
  async function parseData(rawData: CsvData): Promise<Layer[]> {
    const rasterData = await GuppyService.getRastersByCategory(
      'eu-composite-mapping'
    );

    const layers = rawData.data.map((data): Layer | null => {
      const uuid = `${data.category}_${data.raster_name}_${data.version}`;

      const rasterItem = find(rasterData, { uuid });

      if (!rasterItem) {
        console.warn(
          `Can not find layer with uuid ${uuid} availlable in Guppy.`
        );
        return null;
      }

      return {
        uuid,
        rasterName: data.raster_name,
        scope: data.type,
        name: data.viewer_name,
        disclaimer: data.disclaimer,
        pollutantType: data.pollutant,
        period: data.version,
        sector: data.sector,
        bounds: rasterItem.boundingBox4326,
        wmsLayer: {
          url: `${process.env.REACT_APP_GUPPY_GEOSERVER_URL}/wms`,
          layers: `eu-composite-mapping:${uuid}`,
          styles: 'guppy:jet-dynamic',
          opacity: 0.7,
          format: 'image/png',
          transparent: true,
        },
        isActive: false,

        _file: data.file,
      };
    });

    const uniqueLayers = layers.reduce(
      (uniqueLayers: Layer[], layer: Layer | null) => {
        if (!layer) return uniqueLayers;

        // check for duplicate uuid definitions, only log the conflicts
        const result = uniqueLayers.filter(
          uniqueLayer => uniqueLayer.uuid === layer.uuid
        );
        if (!isEmpty(result)) {
          console.warn(`The layer with uuid ${layer.uuid} exists 2 times`);
          console.table([...result, layer], ['uuid', 'name', 'rasterName']);
        }

        // check for duplicate layer definitions, only log the conflicts
        const result2 = uniqueLayers.filter(
          uniqueLayer =>
            uniqueLayer.name === layer.name &&
            uniqueLayer.period === layer.period &&
            uniqueLayer.pollutantType === layer.pollutantType
        );
        if (!isEmpty(result2)) {
          console.warn(`The layer with name ${layer.name} exists 2 times`);
          console.table(
            [...result2, layer],
            [
              'uuid',
              '_file',
              'name',
              'rasterName',
              'pollutantType',
              'period',
              'sector',
            ]
          );
        }

        return [...uniqueLayers, layer];
      },
      []
    );
    return uniqueLayers;
  }

  const parsedData = await parseData(rawData);

  return parsedData;
}
