import { Baselayer } from '../types';

export const baselayers: Baselayer[] = [
  {
    // OpenStreetMap_Mapnik
    key: 'street-map',
    title: 'Street map',
    description: '',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&#169;<a href=&quot;https://osm.org/copyright&quot;>OpenStreetMap</a> contributors',
    image: 'images/map-preview/street-map@2x.png',
    isActive: true,
  },
  {
    // OpenStreetMap_BlackAndWhite
    key: 'black-white',
    title: 'Grayscale map',
    description: '',
    url: 'http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png',
    attribution:
      '&#169;<a href=&quot;https://osm.org/copyright&quot;>OpenStreetMap</a> contributors',
    image: 'images/map-preview/black-white@2x.png',
    isActive: false,
  },
  {
    // Esri_WorldImagery
    key: 'world-imagery',
    title: 'Satellite map',
    description: '',
    url:
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    attribution:
      'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    image: 'images/map-preview/world-imagery@2x.png',
    isActive: false,
  },
  {
    // cartoDB
    key: 'low-contrast',
    title: 'Low contrast map',
    description: '',
    url:
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png',
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>',
    image: 'images/map-preview/low-contrast@2x.png',
    isActive: false,
  },
];
