import { combineReducers } from 'redux';
import { mapReducer } from './map/reducers';
import { selectionReducer } from './selection/reducers';
import { dataReducer } from './data/reducers';

const rootReducer = combineReducers({
  map: mapReducer,
  selection: selectionReducer,
  data: dataReducer,
});

export default rootReducer;
