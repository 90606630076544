import React from 'react';
import { GeoJsonObject, Feature } from 'geojson';
import { circleMarker, LatLng } from 'leaflet';
import { GeoJSON, withLeaflet, LeafletContext } from 'react-leaflet';
import { isEqual } from 'lodash-es';
import { Pollutant } from './../../types';
// import styles from './styles.module.scss';

interface Props {
  geoData: GeoJsonObject | null;
  pollutant: Pollutant;
  leaflet: LeafletContext;
}

class AirbaseStationsOnMap extends React.Component<Props, {}> {
  private key: number = 1;
  private geoDataCache: string = '';

  pointToLayer(feature: Feature, latlng: LatLng) {
    const computedColor: string = feature.properties!.fillColor || 'white';
    return circleMarker(latlng, {
      radius: 5,
      stroke: true,
      color: 'black',
      weight: 1,
      opacity: 1,
      fillColor: computedColor,
      fillOpacity: 0.8,
    }).bindPopup(
      `${this.props.pollutant.type} value: ${
        feature.properties!.concentrat
      } μg/m³`
    );
  }

  updateKey() {
    if (!isEqual(this.geoDataCache, JSON.stringify(this.props.geoData))) {
      this.key++;
      this.geoDataCache = JSON.stringify(this.props.geoData);
    }
  }

  render() {
    const { geoData } = this.props;
    if (!geoData) return null;

    this.updateKey();

    return (
      <GeoJSON
        key={this.key}
        data={geoData}
        pointToLayer={this.pointToLayer.bind(this)}
      />
    );
  }
}

export default withLeaflet(AirbaseStationsOnMap);
