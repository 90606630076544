import { LatLngBounds } from 'leaflet';
import intersect from '@turf/intersect';
import wkx from 'wkx';
import { latLngBoundsToGeometry } from './../utils/geometry.utils';
import { Layer, Pollutant } from './../types';
import { isEmpty } from 'lodash-es';

export function filterLayers(
  layers: Layer[],
  pollutant: Pollutant,
  periods: string[],
  sectors: string[] | null,
  boundary: LatLngBounds | null,
  query: string
): Layer[] {
  if (isEmpty(layers)) return layers;

  const filteredByPollutant = filterByPollutant(layers, pollutant);
  const filteredByPeriods = filterByPeriods(filteredByPollutant, periods);
  const filteredBySectors = filterBySectors(filteredByPeriods, sectors);
  const filteredByBbox = filterByBoundingBox(filteredBySectors, boundary);
  const filteredByQuery = filterByQuery(filteredByBbox, query);

  return filteredByQuery;
}

function filterByBoundingBox(
  layers: Layer[],
  boundary: LatLngBounds | null
): Layer[] {
  const boundaryPolygon = latLngBoundsToGeometry(boundary);
  const filteredLayers = layers.filter(layer => {
    // if no boundary set, all layers match
    if (!boundary) return true;
    // else check if layer bounds overlap the current viewport (boundary)
    const layerPolygon = wkx.Geometry.parse(layer.bounds).toGeoJSON();

    // @ts-ignore
    const intersection = intersect(boundaryPolygon, layerPolygon);

    if (intersection === null) return false;
    return true;
  });

  return filteredLayers;
}

function filterByPeriods(layers: Layer[], periods: string[]): Layer[] {
  return layers.filter(layer => periods.includes(layer.period));
}

function filterBySectors(layers: Layer[], sectors: string[] | null): Layer[] {
  if (!sectors) return layers;
  return layers.filter(layer => sectors.includes(layer.sector));
}

function filterByPollutant(layers: Layer[], pollutant: Pollutant): Layer[] {
  return layers.filter(layer => layer.pollutantType === pollutant.type);
}

function filterByQuery(layers: Layer[], query: string): Layer[] {
  return layers.filter(layer =>
    layer.name.toLowerCase().includes(query.toLowerCase())
  );
}
