import {
  SET_MAP_POSITION,
  MapState,
  MapActionTypes,
  SET_MAP_BOUNDS,
} from './types';

// TODO: refactor key names to baselayerKey, ... to indicate reference to key attr
export const initialState: MapState = {
  position: {
    lat: 50.5,
    lng: 4.4,
    zoom: 5,
  },
  bounds: null,
};

export const mapReducer = (
  state = initialState,
  action: MapActionTypes
): MapState => {
  switch (action.type) {
    case SET_MAP_POSITION:
      return {
        ...state,
        position: action.payload,
      };
    case SET_MAP_BOUNDS:
      return {
        ...state,
        bounds: [action.payload.getSouthWest(), action.payload.getNorthEast()],
      };

    default:
      return state;
  }
};
