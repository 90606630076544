import { createSelector } from 'reselect';
import { GeoJsonObject } from 'geojson';
import { Pollutant } from '../types';
import {
  AirbaseStationTypes,
  Year,
} from '../components/airbase.component/airbase.component';
import { getGeoJsonData } from '../json/geoJson.mapper';

const getGeoData = (
  pollutant: Pollutant,
  legendMinMax: { min: number; max: number } | null,
  year: Year,
  airbaseStationTypes: AirbaseStationTypes
) => {
  if (!legendMinMax) return null;
  const geoData = getGeoJsonData(
    pollutant,
    year,
    airbaseStationTypes,
    legendMinMax
  );
  return geoData;
};

export const getAirbaseData = createSelector(
  [getGeoData],
  (geoData): GeoJsonObject | null => {
    if (!geoData) return null;
    return geoData as GeoJsonObject;
  }
);
