import { Layer } from '../../types';

export const SET_OVERLAYERS_DATA = 'SET_OVERLAYERS_DATA';

export interface DataState {
  overlayers: Layer[];
}

interface SetOverlayersDataAction {
  type: typeof SET_OVERLAYERS_DATA;
  payload: Layer[];
}
export type DataActionTypes = SetOverlayersDataAction;
