import React from 'react';
import { noop } from 'lodash-es';
import { Typography } from 'marvin-ui-kit';
import { Layer } from '../../types';
import Icon from './../icon';
import styles from './styles.module.scss';

interface Props {
  layers: Layer[];
  onDelete: (layer: Layer) => void;
}

const ActiveLayers = (props: Props) => {
  const { layers } = props;
  return (
    <div className={styles.container}>
      <Typography.H1 className={styles.title}>Active layers</Typography.H1>
      <div className={styles.layerList}>
        {layers.map((layer, i) => (
          <div key={i} className={styles.layerItem}>
            <Icon
              name="times-circle-solid"
              size={15}
              fill="#359EA6"
              onClick={() => props.onDelete(layer)}
            />
            <span className={styles.layerItemLabel}>{layer.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

ActiveLayers.defaultProps = {
  onDelete: noop,
};

export { ActiveLayers };
