import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_GUPPY_API_URL;

export interface Raster {
  uuid: string;
  name: string;
  description: string;
  metadataUrl: string;
  createdOn: Date;
  createdBy: string;
  updatedOn: Date;
  updatedBy: string;
  boundingBoxNative: string;
  boundingBox4326: string;
}

export interface RasterStats {
  count: number;
  sum: number;
  mean: number;
  stddev: number;
  min: number;
  max: number;
}

export interface RasterBody {
  srs: string;
  geometry: string;
  resolution: 'native' | 'auto';
}

export interface RasterLinedataBody extends RasterBody {
  datapoints: number;
}

export async function getRastersByCategory(
  category: string,
  options = { limit: 9999 }
): Promise<Raster[]> {
  const result = await axios.get(
    `${API_BASE_URL}/categories/${category}/rasters`,
    { params: { limit: options.limit } }
  );
  return result.data.data;
}

export async function getRasters(): Promise<Raster[]> {
  const result = await axios.get(`${API_BASE_URL}/rasters`);
  return result.data.data;
}

export async function getRaster(uuid: string): Promise<Raster> {
  const result = await axios.get(`${API_BASE_URL}/rasters/${uuid}`);
  return result.data.data;
}

export async function getRasterStats(
  uuid: string,
  body: RasterBody
): Promise<RasterStats> {
  const url = `${API_BASE_URL}/rasters/${uuid}/stats`;
  const result = await axios.post(url, body);
  return result.data.data;
}

interface RasterQuantilesBody extends RasterBody {
  quantiles: number[];
}

interface RasterQuantiles {
  quantile: number;
  value: number;
}

export async function getRasterQuantiles(
  uuid: string,
  body: RasterQuantilesBody
): Promise<RasterQuantiles> {
  const url = `${API_BASE_URL}/rasters/${uuid}/quantiles`;
  const result = await axios.post(url, body);
  return result.data.data;
}

export async function getRasterData(
  uuid: string,
  body: RasterBody
): Promise<number[][]> {
  const url = `${API_BASE_URL}/rasters/${uuid}/data`;
  const result = await axios.post(url, body);
  return result.data.data;
}

export async function getRasterLinedata(
  uuid: string,
  body: RasterLinedataBody
): Promise<number[]> {
  const url = `${API_BASE_URL}/rasters/${uuid}/linedata`;
  // TODO: define datapoints as a function argument
  const result = await axios.post(url, { ...body });
  return result.data.data;
}
