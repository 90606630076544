import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { persistStore } from 'redux-persist';
import {
  RouteComponentProps,
  Switch,
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom';

import { RootState, APPLICATION_SCOPE } from '../../types';
import { store } from '../../store';

import AppContainer from './../app.container';
import { Landing } from '../../components/landing';
import { Preloader } from '../../components/preloader';
import { getApplicationScope } from '../../selectors';
import { setApplicationScope } from '../../redux/selection/actions';
import { loadScopeSelection, loadData } from '../../redux/init/actions';
import styles from './styles.module.scss';

interface OwnProps {
  store: any;
}

interface StateProps {
  scope: APPLICATION_SCOPE | null;
}

type Props = OwnProps & StateProps & DispatchProp & RouteComponentProps;

interface State {
  isInitialized: boolean;
  isError: boolean;
  error: Error;
}

function defineScope(pathname: string): APPLICATION_SCOPE | null {
  if (pathname.includes('emission')) return APPLICATION_SCOPE.emission;
  if (pathname.includes('concentration'))
    return APPLICATION_SCOPE.concentration;
  return null;
}

class RootContainer extends React.Component<Props, State> {
  state = {
    isInitialized: false,
    isError: false,
    error: new Error(),
  };

  componentDidMount() {
    this.loadInitialData();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.persistScope();
    }
  }

  persistScope() {
    const pathScope = defineScope(this.props.location.pathname);

    // if new scope in path, persist the scope and load scope selection
    if (pathScope && this.props.scope !== pathScope) {
      this.props.dispatch(setApplicationScope(pathScope) as any);
      this.props.dispatch(loadScopeSelection() as any);
    }
  }

  loadInitialData() {
    persistStore(store, {}, async () => {
      try {
        await this.props.dispatch(loadData() as any);
        await this.persistScope();
        this.setState({ isInitialized: true });
      } catch (ex) {
        this.setState({
          error: ex,
          isError: true,
        });
      }
    });
  }

  render() {
    return (
      <Preloader isLoading={!this.state.isInitialized}>
        <>
          {this.state.isError && this.renderError()}
          {!this.state.isError &&
            this.state.isInitialized &&
            this.renderSuccess()}
        </>
      </Preloader>
    );
  }

  renderSuccess() {
    return (
      <Switch>
        <Route
          path={['/emissions', '/concentrations']}
          component={AppContainer}
        ></Route>
        <Route path="/" exact component={Landing}></Route>
        <Redirect to="/" />
      </Switch>
    );
  }

  renderError() {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>
          Error while loading...
          <div className={styles.errorDetail}>
            {this.state.error.toString()}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return { scope: getApplicationScope(state) };
}

export default withRouter(connect(mapStateToProps)(RootContainer));
