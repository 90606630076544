import { CrossSection, AreaEmissions } from './../types/index';
import { CRS } from 'leaflet';
import wkx from 'wkx';
import { min, max, flatten } from 'lodash-es';
import area from '@turf/area';
import { Layer } from '../types';
import * as GuppyService from './guppy.service';
import { Drawing } from '../components/draw-control/draw-control';

export async function getCrossSectionData(
  layers: Layer[],
  drawing: Drawing
): Promise<CrossSection[]> {
  const wkt = parseDrawingToWKT(drawing);

  const dataPromises = layers.map(
    async (layer): Promise<CrossSection> => {
      const rasterData: number[] = await GuppyService.getRasterLinedata(
        layer.uuid,
        {
          srs: CRS.EPSG4326.code!,
          geometry: wkt,
          resolution: 'auto',
          datapoints: 400,
        }
      );

      return {
        layer,
        // TODO: this is not a correct dataset to visualise
        data: rasterData,
      };
    }
  );

  const data = await Promise.all(dataPromises);
  return data;
}

export async function getAreaEmissions(
  layers: Layer[],
  drawing: Drawing
): Promise<AreaEmissions[]> {
  const wkt = parseDrawingToWKT(drawing);

  const dataPromises = layers.map(async layer => {
    const rasterData: any = await GuppyService.getRasterStats(layer.uuid, {
      srs: CRS.EPSG4326.code!,
      geometry: wkt,
      resolution: 'auto',
    });

    // square area of raster selection
    const rasterArea = area(drawing.layer!) / (1000 * 1000);

    return {
      layer,
      count: rasterData.count,
      sum: rasterData.mean,
      emissionsByKM2: rasterData.mean,
      emissions: rasterData.mean * rasterArea,
    };
  });

  const data = await Promise.all(dataPromises);

  // filter data on count, this means that data is included in the query wkt
  const filteredData = data.filter(dataItem => dataItem.count !== 0);
  return filteredData;
}

function parseDrawingToWKT(drawing: Drawing): string {
  const wkt = wkx.Geometry.parseGeoJSON(drawing.layer!.geometry).toWkt();
  return wkt;
}

interface LegendMinMax {
  min: number;
  max: number;
}
export async function getLegendMinMax(layers: Layer[], wkt: string) {
  const body = {
    geometry: wkt,
    srs: CRS.EPSG4326.code!,
    resolution: 'auto' as 'auto',
    quantiles: [0.02, 0.98],
  };

  const overlayersQuantiles = await Promise.all(
    layers.map(async overlayer => {
      return GuppyService.getRasterQuantiles(overlayer.uuid, body);
    })
  );

  const quantileValues = flatten(overlayersQuantiles).map(q => q.value);
  const legendMin = min(quantileValues);
  const legendMax = max(quantileValues);
  return { min: legendMin, max: legendMax };
}
