import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, Theme } from 'marvin-ui-kit';
import AppContainer from './containers/root.container';
import * as serviceWorker from './serviceWorker';
import { store } from './store';

import L from 'leaflet'; // eslint-disable-line
import Draw from 'leaflet-draw'; // eslint-disable-line
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './styles/app.scss';
import 'react-leaflet-markercluster/dist/styles.min.css';

const MOUNT_NODE = document.getElementById('root');

export const theme: Theme = {
  typography: {
    baseFontSize: 12,
    h1: {
      fontSize: 16,
      fontFamily: 'Open Sans Condensed, serif',
    },
  },
};

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Route path="/" component={AppContainer} />
        </Router>
      </ThemeProvider>
    </Provider>,
    MOUNT_NODE
  );
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
