import React from 'react';
import { LeafletContext, withLeaflet } from 'react-leaflet';
import { LatLngBounds } from 'leaflet';

interface Props {
  render: ({ bounds }: { bounds: LatLngBounds }) => React.ReactElement<any>;
  leaflet: LeafletContext;
}
class MapBounds extends React.Component<Props, {}> {
  render() {
    const { leaflet } = this.props;
    if (leaflet.map === undefined) return null;
    const bounds = leaflet.map.getBounds();
    return this.props.render({ bounds });
  }
}

export default withLeaflet(MapBounds);
