import { Feature, FeatureCollection, GeoJsonObject } from 'geojson';
import * as pm102012Data from './PM10-2012.json';
import * as pm102013Data from './PM10-2013.json';
import * as pm102014Data from './PM10-2014.json';
import * as pm102015Data from './PM10-2015.json';
import * as pm102016Data from './PM10-2016.json';
import * as pm102017Data from './PM10-2017.json';
import * as no22012Data from './NO2-2012.json';
import * as no22013Data from './NO2-2013.json';
import * as no22014Data from './NO2-2014.json';
import * as no22015Data from './NO2-2015.json';
import * as no22016Data from './NO2-2016.json';
import * as no22017Data from './NO2-2017.json';
import {
  AirbaseStationTypes,
  Year,
} from '../components/airbase.component/airbase.component';
import {
  colorMapWithCustomRange,
  interpolateColor,
} from '../utils/legend.utils';
import { Pollutant } from '../types/index';

// TODO: figure out the typings
interface AirbaseGeoData {
  PM10_concentrations: any;
  NO2: any;
}

const GEODATA: AirbaseGeoData = {
  PM10_concentrations: {
    2012: (pm102012Data as any).default,
    2013: (pm102013Data as any).default,
    2014: (pm102014Data as any).default,
    2015: (pm102015Data as any).default,
    2016: (pm102016Data as any).default,
    2017: (pm102017Data as any).default,
  },
  NO2: {
    2012: (no22012Data as any).default,
    2013: (no22013Data as any).default,
    2014: (no22014Data as any).default,
    2015: (no22015Data as any).default,
    2016: (no22016Data as any).default,
    2017: (no22017Data as any).default,
  },
};

export function getGeoJsonData(
  pollutant: Pollutant,
  year: Year,
  airbaseStationTypes: AirbaseStationTypes,
  legendMinMax: { min: number; max: number }
): GeoJsonObject | null {
  const { min, max } = legendMinMax;
  const data = getFeaturesWithColors(
    pollutant.colorMap,
    min,
    max,
    // @ts-ignore
    GEODATA[pollutant.type][year.value].features
  );
  if (airbaseStationTypes.background && airbaseStationTypes.local) {
    return {
      // @ts-ignore
      ...GEODATA[pollutant.type][year.value],
      features: [...data],
    };
  } else if (airbaseStationTypes.background) {
    return getDataByAirbaseType(data, pollutant.type, year, [1]);
  } else if (airbaseStationTypes.local) {
    return getDataByAirbaseType(data, pollutant.type, year, [2, 3]);
  } else {
    return null;
  }
}

function getFeaturesWithColors(
  colorMap: any,
  min: number,
  max: number,
  features: Feature[]
): Feature[] {
  const featuresWithColors: Feature[] = features;
  featuresWithColors.forEach((feature: Feature) => {
    feature!.properties!.fillColor = renderDivColor(
      colorMap,
      min,
      max,
      feature
    );
  });
  return featuresWithColors;
}

function getDataByAirbaseType(
  data: Feature[],
  pollutantType: string,
  year: Year,
  airbaseTypes: number[]
): FeatureCollection {
  const filteredFeatures: Feature[] = data.filter((feature: Feature) => {
    return airbaseTypes.includes(feature!.properties!.type);
  });
  return {
    // @ts-ignore
    ...GEODATA[pollutantType][year.value],
    features: filteredFeatures,
  };
}

// TODO: define colorMap definition
function renderDivColor(
  colorMap: any,
  min: number,
  max: number,
  feature: Feature
): string {
  const customColorMap = colorMapWithCustomRange(colorMap, min, max);
  return interpolateColor(customColorMap, feature!.properties!.concentrat);
}
