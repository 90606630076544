import { MapPosition } from '../../types';
import { SET_MAP_POSITION, MapActionTypes, SET_MAP_BOUNDS } from './types';
import { LatLngBounds } from 'leaflet';

export function setMapPosition(position: MapPosition): MapActionTypes {
  return {
    type: SET_MAP_POSITION,
    payload: position,
  };
}

export function setMapBounds(bounds: LatLngBounds): MapActionTypes {
  return {
    type: SET_MAP_BOUNDS,
    payload: bounds,
  };
}
