import * as React from 'react';
import { Link } from 'react-router-dom';
import Icon from './../icon';
import { HeaderContainer } from '../../containers/header.container';
import styles from './styles.module.scss';

interface Props {
  isLoading: boolean;
  children: JSX.Element;
}

export const Landing = (props: Props) => {
  return (
    <div className={styles.container}>
      <HeaderContainer></HeaderContainer>
      <div className={styles.body}>
        <div className={styles.title}>EU Composite Maps</div>
        <p>Please select an application scope</p>
        <div className={styles.scopeSelector}>
          <div className={styles.scopeItem}>
            <Icon name="cloud" fill="white" size={66} />
            <Link to="/concentrations" className={styles.btnScope}>
              Open concentrations app
            </Link>
          </div>
          <div className={styles.scopeItem}>
            <Icon name="car" fill="white" size={50} />
            <Link to="/emissions" className={styles.btnScope}>
              Open emissions app
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.logos}>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt="Fairmode - Forum for air quality modelling in Europe"
          width={180}
          height={31}
        />
      </div>
    </div>
  );
};
