import { Pollutant } from '../types';

export const pollutants: Pollutant[] = [
  {
    type: 'PM10_concentrations',
    name: 'PM10',
    entity: 'µg/m³',
    colorMap: {
      entries: [
        { color: '#4749A3', quantity: 0 },
        { color: '#4C4CCA', quantity: 4 },
        { color: '#4C5DFD', quantity: 8 },
        { color: '#4BA4FD', quantity: 12 },
        { color: '#4DEEF5', quantity: 16 },
        { color: '#84FAB8', quantity: 20 },
        { color: '#C4FE80', quantity: 24 },
        { color: '#F9ED48', quantity: 28 },
        { color: '#FFB14B', quantity: 32 },
        { color: '#FB6D4B', quantity: 36 },
        { color: '#C34B4B', quantity: 40 },
      ],
    },
  },
  {
    type: 'PM10_emissions',
    name: 'PM10',
    entity: 'T/km²/year',
    colorMap: {
      entries: [
        { color: '#4749A3', quantity: 0 },
        { color: '#4C4CCA', quantity: 0.5 },
        { color: '#4C5DFD', quantity: 1.0 },
        { color: '#4BA4FD', quantity: 1.5 },
        { color: '#4DEEF5', quantity: 2.0 },
        { color: '#84FAB8', quantity: 2.5 },
        { color: '#C4FE80', quantity: 3.0 },
        { color: '#F9ED48', quantity: 3.5 },
        { color: '#FFB14B', quantity: 4.0 },
        { color: '#FB6D4B', quantity: 4.5 },
        { color: '#C34B4B', quantity: 5.0 },
      ],
    },
  },
  {
    type: 'PM25',
    name: 'PM25',
    entity: 'T/km²/year',
    colorMap: {
      entries: [
        { color: '#4749A3', quantity: 0.0 },
        { color: '#4C4CCA', quantity: 0.5 },
        { color: '#4C5DFD', quantity: 1.0 },
        { color: '#4BA4FD', quantity: 1.5 },
        { color: '#4DEEF5', quantity: 2.0 },
        { color: '#84FAB8', quantity: 2.5 },
        { color: '#C4FE80', quantity: 3.0 },
        { color: '#F9ED48', quantity: 3.5 },
        { color: '#FFB14B', quantity: 4.0 },
        { color: '#FB6D4B', quantity: 4.5 },
        { color: '#C34B4B', quantity: 5.0 },
      ],
    },
  },
  {
    type: 'NO2',
    name: 'NO2',
    entity: 'µg/m³',
    colorMap: {
      entries: [
        { color: '#4749A3', quantity: 0 },
        { color: '#4C4CCA', quantity: 4.5 },
        { color: '#4C5DFD', quantity: 9 },
        { color: '#4BA4FD', quantity: 14 },
        { color: '#4DEEF5', quantity: 18 },
        { color: '#84FAB8', quantity: 23 },
        { color: '#C4FE80', quantity: 27 },
        { color: '#F9ED48', quantity: 32 },
        { color: '#FFB14B', quantity: 36 },
        { color: '#FB6D4B', quantity: 41 },
        { color: '#C34B4B', quantity: 45 },
      ],
    },
  },
  {
    type: 'CO',
    name: 'CO',
    entity: 'T/km²/year',
    colorMap: {
      entries: [
        { color: '#4749A3', quantity: 0.0 },
        { color: '#4C4CCA', quantity: 2.5 },
        { color: '#4C5DFD', quantity: 5.0 },
        { color: '#4BA4FD', quantity: 7.5 },
        { color: '#4DEEF5', quantity: 10 },
        { color: '#84FAB8', quantity: 13 },
        { color: '#C4FE80', quantity: 15 },
        { color: '#F9ED48', quantity: 18 },
        { color: '#FFB14B', quantity: 20 },
        { color: '#FB6D4B', quantity: 23 },
        { color: '#C34B4B', quantity: 25 },
      ],
    },
  },
  {
    type: 'NH3',
    name: 'NH3',
    entity: 'T/km²/year',
    colorMap: {
      entries: [
        { color: '#4749A3', quantity: 3.3e-16 },
        { color: '#4C4CCA', quantity: 0.3 },
        { color: '#4C5DFD', quantity: 0.6 },
        { color: '#4BA4FD', quantity: 0.9 },
        { color: '#4DEEF5', quantity: 1.2 },
        { color: '#84FAB8', quantity: 1.5 },
        { color: '#C4FE80', quantity: 1.8 },
        { color: '#F9ED48', quantity: 2.1 },
        { color: '#FFB14B', quantity: 2.4 },
        { color: '#FB6D4B', quantity: 2.7 },
        { color: '#C34B4B', quantity: 3.0 },
      ],
    },
  },
  {
    type: 'NOx',
    name: 'NOx',
    entity: 'T/km²/year',
    colorMap: {
      entries: [
        { color: '#4749A3', quantity: 0.0 },
        { color: '#4C4CCA', quantity: 1.5 },
        { color: '#4C5DFD', quantity: 3.0 },
        { color: '#4BA4FD', quantity: 4.5 },
        { color: '#4DEEF5', quantity: 6.0 },
        { color: '#84FAB8', quantity: 7.5 },
        { color: '#C4FE80', quantity: 9.0 },
        { color: '#F9ED48', quantity: 11 },
        { color: '#FFB14B', quantity: 12 },
        { color: '#FB6D4B', quantity: 14 },
        { color: '#C34B4B', quantity: 15 },
      ],
    },
  },
  {
    type: 'SO2',
    name: 'SO2',
    entity: 'T/km²/year',
    colorMap: {
      entries: [
        { color: '#4749A3', quantity: 0.0 },
        { color: '#4C4CCA', quantity: 2.5 },
        { color: '#4C5DFD', quantity: 5.0 },
        { color: '#4BA4FD', quantity: 7.5 },
        { color: '#4DEEF5', quantity: 10 },
        { color: '#84FAB8', quantity: 13 },
        { color: '#C4FE80', quantity: 15 },
        { color: '#F9ED48', quantity: 18 },
        { color: '#FFB14B', quantity: 20 },
        { color: '#FB6D4B', quantity: 23 },
        { color: '#C34B4B', quantity: 25 },
      ],
    },
  },
  {
    type: 'NMVOC',
    name: 'NMVOC',
    entity: 'T/km²/year',
    colorMap: {
      entries: [
        { color: '#4749A3', quantity: 0.0 },
        { color: '#4C4CCA', quantity: 2.5 },
        { color: '#4C5DFD', quantity: 5.0 },
        { color: '#4BA4FD', quantity: 7.5 },
        { color: '#4DEEF5', quantity: 10 },
        { color: '#84FAB8', quantity: 13 },
        { color: '#C4FE80', quantity: 15 },
        { color: '#F9ED48', quantity: 18 },
        { color: '#FFB14B', quantity: 20 },
        { color: '#FB6D4B', quantity: 23 },
        { color: '#C34B4B', quantity: 25 },
      ],
    },
  },
];
