import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { PollutantSelector } from './../../components/pollutant-selector';
import { setActivePollutant } from './../../redux/selection/actions';
import {
  getPollutants,
  getActivePollutant,
  getApplicationScope,
} from '../../selectors';
import { APPLICATION_SCOPE } from '../../types';
import styles from './styles.module.scss';

interface Props {}

export const HeaderContainer = (props: Props) => {
  const dispatch = useDispatch();
  const pollutants = useSelector(getPollutants);
  const activePollutant = useSelector(getActivePollutant);
  const scope = useSelector(getApplicationScope);

  const location = useLocation();
  const showPollutantSelector = location.pathname !== '/';

  // render the component
  return render();

  function render() {
    return (
      <div className={styles.container}>
        <NavLink
          to="/"
          exact
          className={styles.title}
          activeClassName={styles.isActive}
        >
          <div className={styles.brand}>EU Composite Maps</div>
          {location.pathname !== '/' && (
            <div className={styles.scope}>
              {scope === APPLICATION_SCOPE.concentration && 'Concentrations'}
              {scope === APPLICATION_SCOPE.emission && 'Emissions'}
            </div>
          )}
        </NavLink>

        {showPollutantSelector && (
          <PollutantSelector
            pollutants={pollutants}
            activePollutant={activePollutant}
            onClick={pollutant => dispatch(setActivePollutant(pollutant))}
          />
        )}

        <div className={styles.spacer} />

        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://fairmode.jrc.ec.europa.eu/ecmaps"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt="Fairmode - Forum for air quality modelling in Europe"
              width={180}
              height={31}
            />
          </a>
        </div>
      </div>
    );
  }
};
