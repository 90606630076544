import { Dispatch } from 'redux';
import { RootState, APPLICATION_SCOPE } from '../../types';
import * as LayerService from './../../services/layer.service';
import { getPeriods, getSectors, getInitialPollutant } from '../../selectors';
import { setOverlayersData } from '../data/actions';
import {
  setActivePeriods,
  setActivePollutant,
  setActiveSectors,
  setActiveOverlayers,
  removeDrawing,
} from '../selection/actions';

// load initial application data
export function loadData() {
  return (dispatch: Dispatch, getState: () => RootState) => {
    return new Promise(async (resolve, reject) => {
      try {
        await setInitialData(dispatch);
        resolve();
      } catch (ex) {
        reject(ex);
      }
    });
  };
}

// load and reset APPLICATION_SCOPE related state and data
export function loadScopeSelection() {
  return (dispatch: Dispatch, getState: () => RootState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const state = getState();
        const { applicationScope } = state.selection;

        // ensure all related actions are dispatched (e.g. legendminmax)
        const initialPollutant = getInitialPollutant(state);
        if (!initialPollutant) {
          throw new Error(
            'Can not continue application initialization without an initial pollutant'
          );
        }
        dispatch(setActivePollutant(initialPollutant));

        // reset all active layers
        dispatch(setActiveOverlayers([]));

        // reset drawing
        dispatch(removeDrawing());

        // initially enable all periods
        const periods = getPeriods(getState());
        dispatch(setActivePeriods(periods));

        if (applicationScope === APPLICATION_SCOPE.emission) {
          // initially enable all sectors
          const sectors = getSectors(getState());
          dispatch(setActiveSectors(sectors));
        }

        if (applicationScope === APPLICATION_SCOPE.concentration) {
          // reset sectors
          dispatch(setActiveSectors(null));
        }
        resolve();
      } catch (ex) {
        reject(ex);
      }
    });
  };
}

async function setInitialData(dispatch: Dispatch) {
  const overlayers = await LayerService.getOverlayers();
  dispatch(setOverlayersData(overlayers));
}
