import { MapPosition } from '../../types';
import L from 'leaflet';
import { LatLngBounds } from 'leaflet';

export const SET_MAP_POSITION = 'SET_MAP_POSITION';
export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS';

export interface MapState {
  position: MapPosition;
  bounds: [L.LatLng, L.LatLng] | null;
}

interface SetMapPositionAction {
  type: typeof SET_MAP_POSITION;
  payload: MapPosition;
}

interface SetMapBoundsAction {
  type: typeof SET_MAP_BOUNDS;
  payload: LatLngBounds;
}
export type MapActionTypes = SetMapPositionAction | SetMapBoundsAction;
