import React from 'react';
import {
  GeoJSON,
  withLeaflet,
  FeatureGroup,
  LeafletContext,
} from 'react-leaflet';
import * as L from 'leaflet';
// @ts-ignore
import { EditControl } from 'react-leaflet-draw';
import { Feature } from 'geojson';
import { MapControlButton } from 'marvin-ui-kit';
import Icon from '../icon';

import styles from './styles.module.scss';
import { APPLICATION_SCOPE } from '../../types';

export interface Drawing {
  id: string;
  layer: Feature | null;
  layerType: L.DrawEvents.Created['layerType'];
}
interface Props {
  leaflet: LeafletContext;
  drawing: Drawing | null;
  // TODO: should drawcontrol know of scope? Maybe define what draw options are availlable instead
  scope: APPLICATION_SCOPE | null;
  onChange: (drawing: Drawing) => void;
}

interface State {
  drawControl: null | { _toolbars: any };
  activeMode: string | null;
}

class DrawControl extends React.Component<Props, State> {
  private style = {
    fillColor: 'black',
    color: 'black',
  };

  state: State = {
    drawControl: null,
    activeMode: null,
  };
  _onCreated = (e: L.DrawEvents.Created) => {
    const { layer, layerType } = e;

    const { layerContainer } = this.props.leaflet;

    if (layerContainer) {
      // remove from global layers list
      layerContainer.removeLayer(layer);

      // construct drawing object
      const drawing = {
        id: `drawing-${new Date().getTime()}`,
        layer: layer.toGeoJSON(),
        layerType: layerType,
      };

      this.props.onChange(drawing);
    }
  };

  _onMounted = (drawControl: any) => {
    this.setState({ drawControl });
  };

  handleDrawPolyline() {
    // @ts-ignore
    this.state.drawControl._toolbars.draw._modes.polyline.handler.enable();
  }

  handleDrawPolygon() {
    // @ts-ignore
    this.state.drawControl._toolbars.draw._modes.polygon.handler.enable();
  }

  handleDrawStart(e: L.DrawEvents.DrawStart) {
    this.setState({ activeMode: e.layerType });
  }

  handleDrawStop(e: L.DrawEvents.DrawStart) {
    this.setState({ activeMode: null });
  }

  render() {
    const { drawing } = this.props;

    return (
      <>
        <MapControlButton
          isactive={this.state.activeMode === 'polyline'}
          onClick={this.handleDrawPolyline.bind(this)}
        >
          <Icon name="ruler" fill="#9BA1AE" size={20} />
        </MapControlButton>

        {this.props.scope === APPLICATION_SCOPE.emission && (
          // Only draw polygon in emissions scope
          <MapControlButton
            isactive={this.state.activeMode === 'polygon'}
            onClick={this.handleDrawPolygon.bind(this)}
          >
            <Icon name="crop-alt" fill="#9BA1AE" size={15} />
          </MapControlButton>
        )}

        <FeatureGroup className={styles.leafletDraw}>
          <EditControl
            position="topleft"
            onCreated={this._onCreated.bind(this)}
            onMounted={this._onMounted.bind(this)}
            onDrawStart={this.handleDrawStart.bind(this)}
            onDrawStop={this.handleDrawStop.bind(this)}
            draw={{
              polygon: { shapeOptions: this.style },
              polyline: { shapeOptions: this.style },
              rectangle: false,
              circle: false,
              circlemarker: false,
              marker: false,
            }}
          />
          {/* TODO: should this component be responsible for visualising the drawn GeoJSON? */}
          {drawing && drawing.layer && (
            <GeoJSON key={drawing.id} data={drawing.layer} style={this.style} />
          )}
        </FeatureGroup>
      </>
    );
  }
}

export default withLeaflet(DrawControl);
