import * as React from 'react';
import { useBoolean } from 'react-hanger';
import classnames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

import { Pollutant } from '../../types';
import Icon from './../icon';
import styles from './styles.module.scss';

interface PollutantSelectorProps {
  pollutants: Pollutant[];
  activePollutant: Pollutant;
  onClick: (pollutant: Pollutant) => void;
}

export const PollutantSelector = (props: PollutantSelectorProps) => {
  const isOpen = useBoolean(false);

  function handleClick(pollutant: Pollutant) {
    isOpen.setFalse();
    props.onClick(pollutant);
  }

  return (
    <div className={styles.selector}>
      <div
        className={`${styles.selectorVisible} ${styles.activePollutant}`}
        onClick={isOpen.toggle}
      >
        <span className={styles.label}>Pollutant:</span>{' '}
        <span className={styles.value}>{props.activePollutant.name}</span>
        <Icon
          name="caret-down"
          size={14}
          fill="white"
          className={styles.caret}
        />
      </div>
      {isOpen.value && (
        <OutsideClickHandler onOutsideClick={() => isOpen.setFalse()}>
          <div className={styles.selectorCollapsed}>
            {props.pollutants.map(pollutant => {
              const cx = classnames({
                [styles.item]: true,
                [styles.itemActive]:
                  pollutant.type === props.activePollutant.type,
              });
              return (
                <div
                  className={cx}
                  key={pollutant.type}
                  onClick={() => handleClick(pollutant)}
                >
                  {pollutant.name}
                </div>
              );
            })}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};
