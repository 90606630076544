export function getGraphLineColor(index: number): string {
  const colors: string[] = [
    '#03DCCE',
    '#FFE25A',
    '#4363d8',
    '#FF851B',
    '#01FF70',
    '#fffac8',
  ];
  return index < colors.length ? colors[index] : getRandomColor();
}

export function getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
