import React, { useEffect } from 'react';
import { CheckBox } from 'marvin-ui-kit';
import { useBoolean } from 'react-hanger';
import { isEqual } from 'lodash-es';
import Icon from './../icon';
import styles from './styles.module.scss';

interface PeriodSelectorToggleProps {}

export const PeriodSelectorToggle = (props: PeriodSelectorToggleProps) => {
  return (
    <div className={styles.toggle}>
      Select
      <Icon
        name="caret-down"
        fill="#FFE25A"
        size={14}
        className={styles.toggleCaret}
      />
    </div>
  );
};

interface PeriodSelectorExpandableProps {
  periods: string[];
  selectedPeriods: string[];
  onTogglePeriod: (period: string) => void;
  onToggleAll: (toggleAll: boolean) => void;
}

export const PeriodSelectorExpandable = (
  props: PeriodSelectorExpandableProps
) => {
  const allSelected = useBoolean(false);

  useEffect(() => {
    const all = isEqual(props.periods, props.selectedPeriods);
    allSelected.setValue(all);
  }, [props.periods, props.selectedPeriods, allSelected]);

  const handleToggleAll = () => {
    props.onToggleAll(!allSelected.value);
  };

  return (
    <div className={styles.expandableContainer}>
      <div className={styles.allSelector} onClick={handleToggleAll}>
        <CheckBox
          status={allSelected.value ? 'checked' : 'unchecked'}
          checkedState={<Icon name="check-square" fill="#359EA6" />}
        />
        (all)
      </div>

      {props.periods.map((period, i) => (
        <div
          key={i}
          onClick={() => props.onTogglePeriod(period)}
          className={styles.periodItem}
        >
          <CheckBox
            status={
              props.selectedPeriods.includes(period) ? 'checked' : 'unchecked'
            }
            checkedState={<Icon name="check-square" fill="#359EA6" />}
          />
          <span>{period}</span>
        </div>
      ))}
    </div>
  );
};
