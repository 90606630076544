import React, { useEffect } from 'react';
import { CheckBox } from 'marvin-ui-kit';
import { useBoolean } from 'react-hanger';
import { isEqual, sortBy, indexOf } from 'lodash-es';
import Icon from '../icon';
import { sectorDict } from '../../constants';
import styles from './styles.module.scss';

interface SectorSelectorToggleProps {}

export const SectorSelectorToggle = (props: SectorSelectorToggleProps) => {
  return (
    <div className={styles.toggle}>
      Select
      <Icon
        name="caret-down"
        fill="#FFE25A"
        size={14}
        className={styles.toggleCaret}
      />
    </div>
  );
};

interface SectorSelectorExpandableProps {
  sectors: string[];
  selectedSectors: string[];
  onToggleSector: (sector: string) => void;
  onToggleAll: (toggleAll: boolean) => void;
}

export const SectorSelectorExpandable = (
  props: SectorSelectorExpandableProps
) => {
  const allSelected = useBoolean(false);

  useEffect(() => {
    const all = isEqual(props.sectors, props.selectedSectors);
    allSelected.setValue(all);
  }, [props.sectors, props.selectedSectors, allSelected]);

  const handleToggleAll = () => {
    props.onToggleAll(!allSelected.value);
  };

  const sortedSectors = sortBy(props.sectors, (sector, index) => {
    const searchIndex = indexOf(sectorDict, sector);
    if (searchIndex === -1) return Infinity;
    return searchIndex;
  });

  return (
    <div className={styles.expandableContainer}>
      <div className={styles.allSelector} onClick={handleToggleAll}>
        <CheckBox
          status={allSelected.value ? 'checked' : 'unchecked'}
          checkedState={<Icon name="check-square" fill="#359EA6" />}
        />
        (all)
      </div>

      {sortedSectors.map((sector, i) => (
        <div
          key={i}
          onClick={() => props.onToggleSector(sector)}
          className={styles.sectorItem}
        >
          <CheckBox
            status={
              props.selectedSectors.includes(sector) ? 'checked' : 'unchecked'
            }
            checkedState={<Icon name="check-square" fill="#359EA6" />}
          />
          <span>{sector}</span>
        </div>
      ))}
    </div>
  );
};
