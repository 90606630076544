import {
  SET_ACTIVE_OVERLAYERS,
  SelectionState,
  SelectionActionTypes,
  SET_ACTIVE_PERIOD,
  SET_ACTIVE_SECTOR,
  SET_ACTIVE_POLLUTANT,
  SET_BOUNDARY_SELECTION,
  SET_LEGEND_MINMAX,
  SET_ACTIVE_AIRBASE_TYPE,
  SET_DRAWING,
  REMOVE_DRAWING,
  SET_APPLICATION_SCOPE,
} from './types';
import { pollutants } from '../../config';

export const initialState: SelectionState = {
  applicationScope: null,
  overlayers: [],
  periods: null,
  sectors: null,
  pollutant: pollutants[0],
  boundary: null,
  legend: null,
  airbaseStationTypes: { local: false, background: false },
  drawing: null,
};

export const selectionReducer = (
  state = initialState,
  action: SelectionActionTypes
): SelectionState => {
  switch (action.type) {
    case SET_APPLICATION_SCOPE:
      return {
        ...state,
        applicationScope: action.payload,
      };

    case SET_ACTIVE_OVERLAYERS:
      return {
        ...state,
        overlayers: action.payload.map(layer => layer.uuid),
      };

    case SET_ACTIVE_PERIOD:
      return {
        ...state,
        periods: action.payload,
      };

    case SET_ACTIVE_SECTOR:
      return {
        ...state,
        sectors: action.payload,
      };

    case SET_ACTIVE_POLLUTANT:
      return {
        ...state,
        pollutant: action.payload,
      };

    case SET_BOUNDARY_SELECTION:
      return {
        ...state,
        boundary: [
          action.payload.getSouthWest(),
          action.payload.getNorthEast(),
        ],
      };

    case SET_LEGEND_MINMAX:
      return {
        ...state,
        legend: action.payload,
      };

    case SET_ACTIVE_AIRBASE_TYPE:
      return {
        ...state,
        airbaseStationTypes: action.payload,
      };

    case SET_DRAWING:
      return {
        ...state,
        drawing: action.payload,
      };

    case REMOVE_DRAWING:
      return {
        ...state,
        drawing: null,
      };

    default:
      return state;
  }
};
