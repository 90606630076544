import { AirbaseStationTypes } from './../../components/airbase.component/airbase.component';
import {
  SelectionActionTypes,
  SET_ACTIVE_OVERLAYERS,
  SET_ACTIVE_PERIOD,
  SET_ACTIVE_POLLUTANT,
  SET_BOUNDARY_SELECTION,
  SET_LEGEND_MINMAX,
  SET_ACTIVE_AIRBASE_TYPE,
  SET_DRAWING,
  REMOVE_DRAWING,
  SET_APPLICATION_SCOPE,
  SET_ACTIVE_SECTOR,
} from './types';
import { Layer, Pollutant, APPLICATION_SCOPE } from '../../types';
import { LatLngBounds } from 'leaflet';
import { Dispatch } from 'redux';
import { max, min } from 'lodash-es';
import { Drawing } from '../../components/draw-control/draw-control';

export function setApplicationScope(
  scope: APPLICATION_SCOPE
): SelectionActionTypes {
  return {
    type: SET_APPLICATION_SCOPE,
    payload: scope,
  };
}

export function setActiveOverlayers(layers: Layer[]): SelectionActionTypes {
  return {
    type: SET_ACTIVE_OVERLAYERS,
    payload: layers,
  };
}

export function setActivePeriods(periods: string[]): SelectionActionTypes {
  return {
    type: SET_ACTIVE_PERIOD,
    payload: periods,
  };
}

export function setActiveSectors(
  sectors: string[] | null
): SelectionActionTypes {
  return {
    type: SET_ACTIVE_SECTOR,
    payload: sectors,
  };
}

export function setActivePollutant(
  pollutant: Pollutant
  // TODO: this should be better typed!!!!
): any | SelectionActionTypes {
  return (dispatch: Dispatch) => {
    dispatch({ type: SET_ACTIVE_POLLUTANT, payload: pollutant });
    const quantities = pollutant.colorMap.entries.map(e => e.quantity);
    dispatch(setLegendMinMax(min(quantities) || 0, max(quantities) || 0));
  };
}

export function setBoundarySelection(
  boundary: LatLngBounds
): SelectionActionTypes {
  return {
    type: SET_BOUNDARY_SELECTION,
    payload: boundary,
  };
}

export function setLegendMinMax(
  min: number,
  max: number
): SelectionActionTypes {
  return { type: SET_LEGEND_MINMAX, payload: { min, max } };
}
export function setActiveAirbaseType(
  airbaseStations: AirbaseStationTypes
): SelectionActionTypes {
  return {
    type: SET_ACTIVE_AIRBASE_TYPE,
    payload: airbaseStations,
  };
}

export function setDrawing(drawing: Drawing): SelectionActionTypes {
  return {
    type: SET_DRAWING,
    payload: drawing,
  };
}

export function removeDrawing(): SelectionActionTypes {
  return {
    type: REMOVE_DRAWING,
  };
}
