import React from 'react';
import Icon from './../icon';
import styles from './styles.module.scss';
import { ColorMap } from '../../types';
import { noop } from 'lodash-es';

interface Props {
  colorMap: ColorMap;
  type: 'ramp' | 'intervals';
  title: string;
  entity: string;
  onCustomize: () => void;
  onReset: () => void;
}

const LegendDynamic = (props: Props) => {
  const { title, entity, colorMap, type } = props;

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <div className={styles.entity}>{entity}</div>
        </div>
        {type === 'ramp' && <LegendRamp colorMap={colorMap} />}
        {type === 'intervals' && <LegendInterval colorMap={colorMap} />}
      </div>
      <div className={styles.actionsContainer}>
        <div className={styles.action} onClick={props.onCustomize}>
          <Icon name="paint-brush" size={15} />
        </div>
        <div className={styles.action} onClick={props.onReset}>
          <Icon name="undo" size={15} />
        </div>
      </div>
    </div>
  );
};

LegendDynamic.defaultProps = {
  type: 'ramp',
  onCustomize: noop,
  onReset: noop,
};

const LegendRamp = (props: { colorMap: ColorMap }) => {
  const { colorMap } = props;
  return (
    <div className={styles.colorMapRamp}>
      <svg width="40">
        <defs>
          <linearGradient id="jet_r" x1="0" x2="0" y1="0" y2="1">
            {colorMap.entries.map((entry, i) => (
              <stop
                key={i}
                offset={`${(i / (colorMap.entries.length - 1)) * 100}%`}
                stopColor={entry.color}
              ></stop>
            ))}
          </linearGradient>
        </defs>

        <rect
          x="0"
          y="0"
          rx="0"
          ry="0"
          width="40"
          height={18 * colorMap.entries.length}
          fill="url(#jet_r)"
          id="legendOriginal"
        ></rect>
      </svg>

      <div className={styles.colorMapEntries}>
        {colorMap.entries.map((entry, i) => (
          <div key={i} className={styles.colorMapEntry}>
            <div className={styles.colorMapEntryLabel}>
              {i === 0 && '≦'}
              {i === colorMap.entries.length - 1 && '≧'}
              {entry.quantity.toFixed(1)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const LegendInterval = (props: { colorMap: ColorMap }) => {
  const { colorMap } = props;
  return (
    <div className={styles.colorMapIntervals}>
      {colorMap.entries.map((entry, i) => (
        <div key={i} className={styles.colorMapEntry}>
          <div
            className={styles.colorMapEntryVisual}
            style={{ backgroundColor: entry.color }}
          />
          <div className={styles.colorMapEntryLabel}>{entry.quantity}</div>
        </div>
      ))}
    </div>
  );
};

export { LegendDynamic };
