import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import {
  RootState,
  Layer,
  CrossSection,
  AreaEmissions,
  Pollutant,
} from '../../types';
import * as DataAnalysisService from './../../services/data-analysis.service';
import { Drawing } from '../../components/draw-control/draw-control';
import {
  getDrawing,
  getActiveOverlayers,
  getActivePollutant,
} from '../../selectors';
import CrossSectionComponent from '../../components/cross-section/cross-section';
import AreaEmissionsChartComponent from '../../components/area-emissions-chart/area-emissions-chart';

interface OwnProps {
  closeGraph: () => void;
}
interface StateProps {
  drawing: Drawing | null;
  overlayers: Layer[];
  pollutant: Pollutant;
}
type Props = OwnProps & StateProps;

const GraphContainer = (props: Props) => {
  const [data, setData] = useState<CrossSection[] | AreaEmissions[] | null>(
    null
  );

  const { overlayers, drawing } = props;

  const fetchCrossSectionData = useCallback(
    async (drawing: Drawing) => {
      const result: CrossSection[] = await DataAnalysisService.getCrossSectionData(
        overlayers,
        drawing
      );
      setData(result);
    },
    [overlayers]
  );

  const fetchAreaEmissionsData = useCallback(
    async (drawing: Drawing) => {
      const result: any = await DataAnalysisService.getAreaEmissions(
        overlayers,
        drawing
      );

      setData(result);
    },
    [overlayers]
  );

  // TODO: this effect could be optimised by checking if a layer is removed (this means no extra data should be fetched)
  useEffect(() => {
    if (!drawing) return;
    if (drawing.layerType === 'polyline') fetchCrossSectionData(drawing);
    if (drawing.layerType === 'polygon') fetchAreaEmissionsData(drawing);
  }, [overlayers, drawing, fetchCrossSectionData, fetchAreaEmissionsData]);

  if (data && drawing) {
    if (drawing.layerType === 'polyline') {
      return (
        <CrossSectionComponent
          pollutant={props.pollutant}
          data={data as CrossSection[]}
          closeGraph={props.closeGraph}
        />
      );
    }

    if (drawing.layerType === 'polygon') {
      return (
        <AreaEmissionsChartComponent
          data={data as AreaEmissions[]}
          pollutant={props.pollutant}
          closeGraph={props.closeGraph}
        />
      );
    }
  }

  // else
  return null;
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    overlayers: getActiveOverlayers(state),
    drawing: getDrawing(state),
    pollutant: getActivePollutant(state),
  };
};
export default connect(mapStateToProps)(GraphContainer);
