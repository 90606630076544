import React from 'react';
import { connect, DispatchProp } from 'react-redux';

import { HeaderContainer } from './../header.container';
import LayersContainer from './../layers.container';
import MapContainer from './../map.container';
import { RootState } from '../../types';
import styles from './styles.module.scss';

interface OwnProps {}
interface StateProps {}
type Props = OwnProps & StateProps & DispatchProp;

const AppContainer = (props: Props) => {
  return (
    <div className={styles.container}>
      <HeaderContainer />

      <div className={styles.panels}>
        <LayersContainer />
        <MapContainer />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

export default connect(mapStateToProps)(AppContainer);
