import { range, round, minBy, maxBy, times, constant } from 'lodash-es';
import { interpolateRgb } from 'd3-interpolate';
import { ColorMap } from '../types';

export function colorMapWithCustomRange(
  colorMap: ColorMap,
  min: number,
  max: number
): ColorMap {
  const length = 10;
  let customRange = [...range(min, max, (max - min) / length), max];
  if (min === null || max === null) {
    customRange = [...times(length, constant(0)), max || 0];
  }

  return {
    ...colorMap,
    entries: colorMap.entries.map((entry, i) => ({
      ...entry,
      quantity: round(customRange[i], 2),
    })),
  };
}

export function interpolateColor(colorMap: ColorMap, value: number) {
  const lowerBound = maxBy(
    colorMap.entries.filter(entry => entry.quantity < value),
    'quantity'
  );
  const upperBound = minBy(
    colorMap.entries.filter(entry => entry.quantity > value),
    'quantity'
  );

  const lowerBoundValue = lowerBound || upperBound;
  const upperBoundValue = upperBound || lowerBound;

  if (!lowerBoundValue || !upperBoundValue) {
    console.warn("Can't interpolate when missing lower or upper bound value");
    return 'pink';
  }

  const getRelativeValue = () => {
    const relativeValue =
      value -
      upperBoundValue.quantity /
        (upperBoundValue.quantity - lowerBoundValue.quantity);

    if (relativeValue < 0) return 0;
    if (relativeValue > 1) return 1;
    return relativeValue;
  };

  const relativeValue = getRelativeValue();
  const result = interpolateRgb(lowerBoundValue.color, upperBoundValue.color)(
    relativeValue
  );
  return result;
}
