import L from 'leaflet';
import { LatLngBounds } from 'leaflet';
import { AirbaseStationTypes } from './../../components/airbase.component/airbase.component';
import { Layer, Pollutant, APPLICATION_SCOPE } from '../../types';
import { Drawing } from '../../components/draw-control/draw-control';

export const SET_APPLICATION_SCOPE = 'SET_APPLICATION_SCOPE';
export const SET_ACTIVE_OVERLAYERS = 'SET_ACTIVE_OVERLAYERS';
export const SET_ACTIVE_PERIOD = 'TOGGLE_ACTIVE_PERIOD';
export const SET_ACTIVE_SECTOR = 'TOGGLE_ACTIVE_SECTOR';
export const SET_ACTIVE_POLLUTANT = 'SET_ACTIVE_POLLUTANT';
export const SET_BOUNDARY_SELECTION = 'SET_BOUNDARY_SELECTION';
export const SET_LEGEND_MINMAX = 'SET_LEGEND_MINMAX';
export const SET_ACTIVE_AIRBASE_TYPE = 'SET_ACTIVE_AIRBASE_TYPE';
export const SET_DRAWING = 'SET_DRAWING';
export const REMOVE_DRAWING = 'REMOVE_DRAWING';

export interface SelectionState {
  applicationScope: APPLICATION_SCOPE | null;
  overlayers: string[];
  periods: string[] | null;
  sectors: string[] | null;
  pollutant: Pollutant;
  boundary: [L.LatLng, L.LatLng] | null;
  legend: {
    min: number;
    max: number;
  } | null;
  airbaseStationTypes: AirbaseStationTypes;
  drawing: Drawing | null;
}

interface SetApplicationScope {
  type: typeof SET_APPLICATION_SCOPE;
  payload: APPLICATION_SCOPE;
}

interface setActiveOverlayersAction {
  type: typeof SET_ACTIVE_OVERLAYERS;
  payload: Layer[];
}

interface SetActivePeriodsAction {
  type: typeof SET_ACTIVE_PERIOD;
  payload: string[];
}

interface SetActiveSectorsAction {
  type: typeof SET_ACTIVE_SECTOR;
  payload: string[] | null;
}

interface SetActivePollutantAction {
  type: typeof SET_ACTIVE_POLLUTANT;
  payload: Pollutant;
}

interface SetBoundarySelectionAction {
  type: typeof SET_BOUNDARY_SELECTION;
  payload: LatLngBounds;
}

interface SetLegendMinMax {
  type: typeof SET_LEGEND_MINMAX;
  payload: { min: number; max: number };
}

interface SetActiveAirbaseType {
  type: typeof SET_ACTIVE_AIRBASE_TYPE;
  payload: AirbaseStationTypes;
}

interface SetDrawingType {
  type: typeof SET_DRAWING;
  payload: Drawing;
}

interface RemoveDrawingType {
  type: typeof REMOVE_DRAWING;
}

export type SelectionActionTypes =
  | SetApplicationScope
  | setActiveOverlayersAction
  | SetActivePeriodsAction
  | SetActiveSectorsAction
  | SetActivePollutantAction
  | SetBoundarySelectionAction
  | SetLegendMinMax
  | SetActiveAirbaseType
  | SetDrawingType
  | RemoveDrawingType;
