export const sectorDict = [
  'S1',
  'S2',
  'S3',
  'S4',
  'S34',
  'S5',
  'S6',
  'S7',
  'S8',
  'S9',
  'S10',
];
