import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import promiseMiddleware from './promise.middleware';
import { persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from '../redux/root-reducer'; // the value from combineReducers
import migrations from './migrations';

const middleware = [thunkMiddleware, promiseMiddleware()];

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({
    collapsed: true,
    logger: console,
  });
  middleware.push(logger);
}

const persistConfig = {
  // TODO: should we move these config items in a global configuration?
  key: 'eu-composite-maps',
  storage,
  version: 2,
  whitelist: ['map', 'selection'],
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(...middleware));

export { store };
