import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteProps } from 'react-router-dom';
import { filter, isEqual, min, max, isNumber } from 'lodash-es';
import { LatLngBounds } from 'leaflet';
import { WMSTileLayer, TileLayer } from 'react-leaflet';
import wkx from 'wkx';
import {
  Map,
  MapControlGroup,
  MapControlButton,
  MapControl,
} from 'marvin-ui-kit';

import * as mapActions from '../../redux/map/actions';
import { Pollutant, APPLICATION_SCOPE } from '../../types';
import {
  getPosition,
  getBounds,
  getActivePollutant,
  getLegendMinMaxSelection,
  getActiveOverlayers,
  getActiveBaselayers,
  getActiveAirbaseStationTypes,
  getDrawing,
  getApplicationScope,
} from '../../selectors';
import GraphContainer from './../graph.container';
import MapBounds from '../../components/map-bounds';
import { LegendDynamic } from '../../components/legend-dynamic';
import { AirbaseStationsOnMap } from '../../components/airbase-stations-on-map';
import DrawControl from '../../components/draw-control';
import { latLngBoundsToGeometry } from '../../utils/geometry.utils';
import {
  removeDrawing,
  setLegendMinMax,
  setDrawing,
} from '../../redux/selection/actions';
import { Baselayer, RootState, MapPosition, Layer } from '../../types';
import Icon from '../../components/icon';
import AirbaseComponent, {
  AirbaseStationTypes,
  Year,
} from '../../components/airbase.component/airbase.component';
import { setActiveAirbaseType } from '../../redux/selection/actions';
import { colorMapWithCustomRange } from '../../utils/legend.utils';
import { getAirbaseData } from '../../selectors/airbase-stations.selector';
import { Drawing } from '../../components/draw-control/draw-control';
import styles from './styles.module.scss';
import { getLegendMinMax } from '../../services/data-analysis.service';

interface StateProps {
  scope: APPLICATION_SCOPE | null;
  bounds: LatLngBounds | null;
  mapPosition: MapPosition;
  baselayers: Array<Baselayer>;
  overlayers: Array<Layer>;
  activePollutant: Pollutant;
  legendMinMax: { min: number; max: number } | null;
  airbaseStationTypes: AirbaseStationTypes;
  drawing: Drawing | null;
}

interface OwnProps {
  mapPosition: MapPosition;
  baselayers: Array<Baselayer>;
  overlayers: Array<Layer>;
}

type Props = OwnProps & StateProps & DispatchProp & RouteProps;

interface State {
  zoom: number;
  lat: number;
  lng: number;
  isLegendVisible: boolean;
  airbaseActiveYear: Year | null;
}

const yearData: Year[] = [
  { value: 2012, layerName: '2012' },
  { value: 2013, layerName: '2013' },
  { value: 2014, layerName: '2014' },
  { value: 2015, layerName: '2015' },
  { value: 2016, layerName: '2016' },
  { value: 2017, layerName: '2017' },
];

class MapContainer extends React.Component<Props, State> {
  legendBtnRef: React.RefObject<HTMLDivElement>;
  infoBtnRef: React.RefObject<HTMLDivElement>;
  periodBtnRef: React.RefObject<HTMLDivElement>;

  state = {
    zoom: this.props.mapPosition.zoom,
    lat: this.props.mapPosition.lat,
    lng: this.props.mapPosition.lng,
    isLegendVisible: true,
    airbaseActiveYear: yearData[0],
  };

  constructor(props: Props) {
    super(props);
    this.legendBtnRef = React.createRef();
    this.infoBtnRef = React.createRef();
    this.periodBtnRef = React.createRef();
  }

  // TODO: define position type
  updateMapPosition(position: any) {
    const { zoom, lat, lng } = position;
    this.setState({ zoom });
    this.props.dispatch(mapActions.setMapPosition({ lat, lng, zoom }));
  }

  handleMapZoom(direction: 'in' | 'out') {
    const { lat, lng } = this.props.mapPosition;
    const { zoom } = this.state;
    if (direction === 'in') {
      this.setState({ zoom: zoom + 1 });
      this.props.dispatch(
        mapActions.setMapPosition({ lat, lng, zoom: zoom + 1 })
      );
    }
    if (direction === 'out') {
      if (zoom === 0) return;
      this.setState({ zoom: zoom - 1 });
      this.props.dispatch(
        mapActions.setMapPosition({ lat, lng, zoom: zoom - 1 })
      );
    }
  }

  handleBoundsChange(bounds: LatLngBounds) {
    if (!isEqual(this.props.bounds, bounds)) {
      this.props.dispatch(mapActions.setMapBounds(bounds));
    }
  }

  handleDrawChange(drawing: Drawing) {
    this.props.dispatch(setDrawing(drawing));
  }

  async handleCustomizeLegend() {
    const boundsGeometry = latLngBoundsToGeometry(this.props.bounds);
    if (boundsGeometry === null) {
      console.warn("Can't customize legend without a bounding box.");
      return;
    }

    const wkt = wkx.Geometry.parseGeoJSON(boundsGeometry.geometry).toWkt();
    const legendMinMax = await getLegendMinMax(this.props.overlayers, wkt);

    if (isNumber(legendMinMax.min) && isNumber(legendMinMax.max)) {
      // set custom legend values
      this.props.dispatch(setLegendMinMax(legendMinMax.min, legendMinMax.max));
    } else {
      // set default legend
      this.resetLegend();
    }
  }
  resetLegend() {
    const { activePollutant } = this.props;

    const quantities = activePollutant.colorMap.entries.map(e => e.quantity);
    this.props.dispatch(
      setLegendMinMax(min(quantities) || 0, max(quantities) || 0)
    );
  }

  setActiveAirbaseType(action: AirbaseStationTypes) {
    this.props.dispatch(setActiveAirbaseType(action));
  }

  setActiveYear(year: Year) {
    this.setState({ airbaseActiveYear: year });
  }

  render() {
    const { zoom, lat, lng } = this.state;
    const { baselayers, overlayers, airbaseStationTypes } = this.props;
    const maxZoom = 18;
    const filteredBaselayers = filter(baselayers, 'isActive');

    const enhancedOverlayers = overlayers.map(layer => ({
      ...layer,
      wmsLayer: {
        ...layer.wmsLayer,
        env:
          this.props.legendMinMax &&
          `min:${this.props.legendMinMax!.min};max:${
            this.props.legendMinMax!.max
          }`,
        tiled: true,
      },
    }));

    return (
      <div className={styles.container}>
        <Map
          zoom={zoom}
          lat={lat}
          lng={lng}
          onChange={this.updateMapPosition.bind(this)}
        >
          {filteredBaselayers.map((layer, i) => (
            <TileLayer key={layer.key} url={layer.url} attribution="" />
          ))}

          {enhancedOverlayers.map((layer, i) => (
            <WMSTileLayer key={layer.wmsLayer.layers} {...layer.wmsLayer} />
          ))}

          <MapBounds
            render={(mbProps: { bounds: LatLngBounds }) => {
              this.handleBoundsChange(mbProps.bounds);
              return <></>;
            }}
          />

          {this.props.scope === APPLICATION_SCOPE.concentration && (
            // only show airbase stations in concentrations scope
            <AirbaseStationsOnMap
              pollutant={this.props.activePollutant}
              geoData={getAirbaseData(
                this.props.activePollutant,
                this.props.legendMinMax,
                this.state.airbaseActiveYear,
                this.props.airbaseStationTypes
              )}
            />
          )}

          <MapControlGroup position="TOP_LEFT">
            <DrawControl
              onChange={this.handleDrawChange.bind(this)}
              drawing={this.props.drawing}
              scope={this.props.scope}
            />
          </MapControlGroup>
        </Map>
        <MapControlGroup position="TOP_RIGHT">
          <MapControl>
            {this.props.scope === APPLICATION_SCOPE.concentration && (
              // only show airbase stations in concentrations scope
              <AirbaseComponent
                airbaseStationTypes={airbaseStationTypes}
                setAirbaseStationTypes={(action: any) => {
                  this.setActiveAirbaseType(action);
                }}
                years={yearData}
                activeYear={this.state.airbaseActiveYear}
                onYearClick={(year: Year) => {
                  this.setActiveYear(year);
                }}
              />
            )}
          </MapControl>
        </MapControlGroup>

        <MapControlGroup position="BOTTOM_LEFT">
          <div className={styles.mapControlGroupRow}>
            {this.renderLegend()}
            <MapControl>
              <GraphContainer
                closeGraph={() => {
                  this.props.dispatch(removeDrawing());
                }}
              />
            </MapControl>
          </div>
        </MapControlGroup>

        <MapControlGroup position="BOTTOM_RIGHT">
          <MapControlButton
            onClick={this.handleMapZoom.bind(this, 'in')}
            disabled={!(zoom < maxZoom)}
          >
            <Icon name="plus" />
          </MapControlButton>
          <MapControlButton
            onClick={this.handleMapZoom.bind(this, 'out')}
            disabled={!(zoom > 0)}
          >
            <Icon name="minus" />
          </MapControlButton>
        </MapControlGroup>
      </div>
    );
  }

  renderLegend() {
    const { activePollutant, legendMinMax } = this.props;

    // guards
    if (this.props.overlayers.length === 0) return null;
    if (!legendMinMax) return null;

    return (
      <MapControl>
        <LegendDynamic
          title={activePollutant.name}
          entity={activePollutant.entity}
          colorMap={colorMapWithCustomRange(
            activePollutant.colorMap,
            legendMinMax.min,
            legendMinMax.max
          )}
          onCustomize={this.handleCustomizeLegend.bind(this)}
          onReset={this.resetLegend.bind(this)}
        />
      </MapControl>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    scope: getApplicationScope(state),
    bounds: getBounds(state),
    mapPosition: getPosition(state),
    baselayers: getActiveBaselayers(state),
    overlayers: getActiveOverlayers(state),
    activePollutant: getActivePollutant(state),
    legendMinMax: getLegendMinMaxSelection(state),
    airbaseStationTypes: getActiveAirbaseStationTypes(state),
    drawing: getDrawing(state),
  };
}

export default connect(mapStateToProps)(MapContainer);
