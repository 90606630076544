import { createSelector } from 'reselect';
import { orderBy, filter, uniq, find } from 'lodash-es';
import L from 'leaflet';
import {
  RootState,
  Baselayer,
  Layer,
  Pollutant,
  APPLICATION_SCOPE,
} from '../types';
import { baselayers, pollutants } from '../config';
import { MapState } from '../redux/map/types';
import { SelectionState } from '../redux/selection/types';
import { DataState } from '../redux/data/types';

export const getRootState = (state: RootState) => state;
export const getSelectionState = (state: RootState): SelectionState =>
  state.selection;
export const getMapState = (state: RootState): MapState => state.map;
export const getDataState = (state: RootState): DataState => state.data;
export const getSelection = (state: RootState) => state.selection;
export const getSelectedOverlayers = (state: RootState) =>
  state.selection.overlayers;
export const getActiveAirbaseStationTypes = (state: RootState) =>
  state.selection.airbaseStationTypes;
export const getApplicationScope = (state: RootState) =>
  state.selection.applicationScope;

export const getInitialPollutant = createSelector(
  [getApplicationScope],
  (scope): Pollutant | undefined => {
    if (scope === APPLICATION_SCOPE.emission)
      return find(pollutants, { type: 'PM10_emissions' });
    if (scope === APPLICATION_SCOPE.concentration)
      return find<Pollutant>(pollutants, { type: 'PM10_concentrations' });
  }
);
export const getActivePollutant = createSelector(
  getRootState,
  (state: RootState): Pollutant => {
    return state.selection.pollutant;
  }
);

export const getPosition = createSelector(
  [getMapState],
  (map: MapState) => {
    return map.position;
  }
);

export const getBounds = createSelector(
  [getMapState],
  map => {
    if (map.bounds) return L.latLngBounds(map.bounds);
    return null;
  }
);
export const getBaselayers = (state: RootState): Baselayer[] => {
  return baselayers;
};

export const getOverlayers = createSelector(
  [getDataState, getSelectedOverlayers, getApplicationScope],
  (data, selectedOverlayers, scope): Layer[] => {
    const layersByScope = data.overlayers.filter(
      layer => layer.scope === scope
    );
    return layersByScope.map(overlayer => ({
      ...overlayer,
      isActive: selectedOverlayers.includes(overlayer.uuid),
    }));
  }
);

export const getActiveBaselayers = createSelector(
  [getBaselayers],
  baselayers => {
    return filter(baselayers, { isActive: true });
  }
);

export const getActiveOverlayers = createSelector(
  [getOverlayers, getActivePollutant],
  (overlayers, pollutant) => {
    return filter(overlayers, {
      isActive: true,
      pollutantType: pollutant.type,
    });
  }
);

export const getBoundarySelection = createSelector(
  [getSelectionState],
  selection => {
    if (selection.boundary) return L.latLngBounds(selection.boundary);
    return null;
  }
);

export const getLegendMinMaxSelection = createSelector(
  [getSelectionState],
  selection => {
    return selection.legend;
  }
);

export const getPollutants = createSelector(
  [getOverlayers],
  (layers): Pollutant[] => {
    const pollutantTypes = uniq(layers.map(layer => layer.pollutantType));

    // map availlable pollutants to application type version
    return pollutants.filter(pollutant =>
      pollutantTypes.includes(pollutant.type)
    );
  }
);

export const getPeriods = createSelector(
  [getOverlayers, getActivePollutant],
  (layers, activePollutant): Array<string> => {
    const uniquePeriods = layers.reduce((periods: string[], layer: Layer) => {
      // filter layers on active pollutant
      if (layer.pollutantType !== activePollutant.type) return periods;

      // don't include duplicate periods
      if (periods.includes(layer.period)) return periods;

      return [...periods, layer.period];
    }, []);

    return orderBy(uniquePeriods);
  }
);

export const getActivePeriods = createSelector(
  [getSelectionState],
  selection => selection.periods || []
);

export const getSectors = createSelector(
  [getOverlayers, getActivePollutant],
  (layers, activePollutant): Array<string> => {
    const uniqueSectors = layers.reduce((sectors: string[], layer: Layer) => {
      // filter layers on active pollutant
      if (layer.pollutantType !== activePollutant.type) return sectors;

      // don't include duplicate sectors
      if (sectors.includes(layer.sector)) return sectors;

      return [...sectors, layer.sector];
    }, []);

    return orderBy(uniqueSectors);
  }
);

export const getActiveSectors = createSelector(
  [getSelectionState],
  selection => selection.sectors || null
);

export const getDrawing = createSelector(
  [getSelection],
  selection => {
    return selection.drawing;
  }
);
