import { MigrationManifest, PersistedState } from 'redux-persist';
import { initialState as initialMapState } from '../redux/map/reducers';
import { initialState as initialSelectionState } from '../redux/selection/reducers';

function getInitialState(state: PersistedState) {
  return {
    ...state,
    map: initialMapState,
    selection: initialSelectionState,
  };
}
const migrations: MigrationManifest = {
  1: (state: PersistedState) => getInitialState(state),
  2: (state: PersistedState) => getInitialState(state),
};

export default migrations;
