import { DataState, DataActionTypes, SET_OVERLAYERS_DATA } from './types';

const initialState: DataState = {
  overlayers: [],
};

export const dataReducer = (
  state = initialState,
  action: DataActionTypes
): DataState => {
  switch (action.type) {
    case SET_OVERLAYERS_DATA:
      return {
        ...state,
        overlayers: action.payload,
      };

    default:
      return state;
  }
};
