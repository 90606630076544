import * as React from 'react';
import { Component } from 'react';
import {
  Toggle,
  CheckBox,
  Dropdown,
  DropdownToggle,
  DropdownItem,
} from 'marvin-ui-kit';

import styles from './styles.module.scss';
import Icon from '../icon';

export interface AirbaseStationTypes {
  local: boolean;
  background: boolean;
}

enum AirbaseStationType {
  LOCAL = 'local',
  BACKGROUND = 'background',
}

export interface Year {
  value: number;
  layerName: string;
}

interface OwnProps {
  setAirbaseStationTypes: (stations: AirbaseStationTypes) => void;
  airbaseStationTypes: AirbaseStationTypes;
  years: Year[];
  activeYear: Year;
  onYearClick: (year: Year) => void;
}

type Props = OwnProps;

interface AirbaseComponentState {
  isActive: boolean;
}

class AirbaseComponent extends Component<Props, AirbaseComponentState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isActive:
        props.airbaseStationTypes.background || props.airbaseStationTypes.local,
    };
  }

  private onClick = () => {
    this.setState(
      {
        isActive: !this.state.isActive,
      },
      () => {
        !this.state.isActive &&
          this.props.setAirbaseStationTypes({
            local: false,
            background: false,
          });
      }
    );
  };

  private showStationType = (stationType: AirbaseStationType) => {
    const activeStationTypes: AirbaseStationTypes = {
      ...this.props.airbaseStationTypes,
    };
    activeStationTypes[stationType] = !activeStationTypes[stationType];
    this.props.setAirbaseStationTypes(activeStationTypes);
  };

  private getCheckboxStatus = (stationType: AirbaseStationType) => {
    return this.props.airbaseStationTypes[stationType]
      ? 'checked'
      : 'unchecked';
  };

  private handleYearClick = (year: Year) => {
    this.props.onYearClick(year);
  };

  render() {
    const { years } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.toggleHeader}>
            <Toggle isActive={this.state.isActive} onClick={this.onClick} />
            <span>Show Airbase Stations</span>
          </div>
          {this.state.isActive && (
            <div className={styles.content}>
              <h2>Analysis year</h2>
              <Dropdown
                toggle={<DropdownToggle value={this.props.activeYear.value} />}
                expandableArea={
                  <div>
                    {years.map((year, i) => (
                      <DropdownItem
                        key={year.value}
                        value={year.value}
                        isActive={this.props.activeYear.value === year.value}
                        onClick={() => this.handleYearClick(year)}
                      />
                    ))}
                  </div>
                }
              />
              <h2>Station type</h2>
              <div
                className={styles.checkboxWrapper}
                onClick={() => this.showStationType(AirbaseStationType.LOCAL)}
              >
                <CheckBox
                  status={this.getCheckboxStatus(AirbaseStationType.LOCAL)}
                  checkedState={<Icon name="check-square" fill="#359EA6" />}
                />
                <span>Local stations</span>
              </div>
              <br />
              <div
                className={styles.checkboxWrapper}
                onClick={() =>
                  this.showStationType(AirbaseStationType.BACKGROUND)
                }
              >
                <CheckBox
                  status={this.getCheckboxStatus(AirbaseStationType.BACKGROUND)}
                  checkedState={<Icon name="check-square" fill="#359EA6" />}
                />
                <span>Background stations</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AirbaseComponent;
