import * as React from 'react';
import {
  XAxis,
  Tooltip,
  CartesianGrid,
  YAxis,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import { round } from 'lodash-es';
import { useBoolean } from 'react-hanger';
import classnames from 'classnames';

import { AreaEmissions, Pollutant } from '../../types';
import { getGraphLineColor } from '../../utils/colors.utils';
import Icon from '../icon';
import styles from './styles.module.scss';

interface AreaEmissionsChartComponentProps {
  closeGraph: () => void;
  data: AreaEmissions[];
  pollutant: Pollutant;
}

type Props = AreaEmissionsChartComponentProps;

export default function AreaEmissionsChartComponent(props: Props) {
  const areaByKM2 = useBoolean(true);

  // TODO: fix any type to something more suiting, problem is that the layer names are dynamic and are being used as object keys
  const mapper = (dataToMap: AreaEmissions[]): Array<any> => {
    const valueObject: any = { name: '' };
    dataToMap.forEach((dataSet: AreaEmissions) => {
      if (areaByKM2.value) {
        valueObject[dataSet.layer.name] = round(dataSet.emissionsByKM2, 2);
      } else {
        valueObject[dataSet.layer.name] = round(dataSet.emissions, 2);
      }
    });

    return [valueObject];
  };

  const getDataKeys = (data: any): string[] => {
    const dataKeys: string[] = data.reduce((array: Object[], object: {}) => {
      return Object.keys(object).reduce((dataKeys: Object[], key: string) => {
        if (dataKeys.indexOf(key) === -1) dataKeys.push(key);
        return dataKeys;
      }, array);
    }, []);
    return dataKeys.filter(key => key !== 'name');
  };

  const graphData: any = mapper(props.data);
  const dataKeys: string[] = getDataKeys(graphData);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.pollutant}>{props.pollutant.name}</div>
        <div className={styles.tabs}>
          <div
            className={classnames(styles.tab, {
              [styles.active]: !areaByKM2.value,
            })}
            onClick={areaByKM2.setFalse}
          >
            ton / year
          </div>
          <div
            className={classnames(styles.tab, {
              [styles.active]: areaByKM2.value,
            })}
            onClick={areaByKM2.setTrue}
          >
            ton / year / km²
          </div>
        </div>
        <span className={styles.closeButton}>
          <Icon
            name="times"
            size={20}
            fill="white"
            onClick={props.closeGraph}
          />
        </span>
      </div>
      <ResponsiveContainer width="100%" height={225}>
        <BarChart
          data={graphData}
          margin={{ top: 0, right: 50, bottom: 0, left: 40 }}
        >
          <CartesianGrid stroke="white" strokeWidth={'.5px'} />
          <XAxis
            dataKey="name"
            tick={false}
            axisLine={{ stroke: 'white', strokeWidth: '2px' }}
          />
          <YAxis
            scale="auto"
            tick={{ fill: 'white' }}
            axisLine={{ stroke: 'white', strokeWidth: '2px' }}
            width={10}
          />
          <Tooltip />
          <Legend iconType={'rect'} />
          {dataKeys.map((key: string, index: number) => {
            const color: string = getGraphLineColor(index);
            return (
              <Bar
                fill={color}
                key={key}
                unit={` ton/year${areaByKM2.value ? '/km²' : ''}`}
                dataKey={key}
                yAxisId={0}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
