import * as React from 'react';
import {
  LineChart,
  XAxis,
  Tooltip,
  CartesianGrid,
  Line,
  YAxis,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { round } from 'lodash-es';

import { getGraphLineColor } from '../../utils/colors.utils';
import { CrossSection, Pollutant } from '../../types';
import styles from './styles.module.scss';
import Icon from '../icon';

interface CrossSectionComponentProps {
  pollutant: Pollutant;
  closeGraph: () => void;
  data: CrossSection[];
}

type Props = CrossSectionComponentProps;

export default function CrossSectionComponent(props: Props) {
  // TODO: fix any type to something more suiting, problem is that the layer names are dynamic and are being used as object keys
  const mapper = (dataToMap: CrossSection[]): any => {
    const mappedData: any = [];
    dataToMap.forEach((dataSet: any) => {
      dataSet.data &&
        dataSet.data.forEach((value: number, dataSetIndex: number) => {
          const valueObject: any = { name: '' };
          if (value !== null) {
            valueObject[dataSet.layer.name] = round(value, 2);
          }
          if (mappedData[dataSetIndex]) {
            mappedData[dataSetIndex] = {
              ...mappedData[dataSetIndex],
              ...valueObject,
            };
          } else {
            mappedData.push(valueObject);
          }
        });
    });
    return mappedData;
  };

  const getDataKeys = (data: any): string[] => {
    const dataKeys: string[] = data.reduce((array: Object[], object: {}) => {
      return Object.keys(object).reduce((dataKeys: Object[], key: string) => {
        if (dataKeys.indexOf(key) === -1) dataKeys.push(key);
        return dataKeys;
      }, array);
    }, []);
    return dataKeys.filter(key => key !== 'name');
  };

  const graphData: any = mapper(props.data);
  const dataKeys: string[] = getDataKeys(graphData);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Cross Section</h1>
        <span className={styles.closeButton}>
          <Icon
            name="times"
            size={20}
            fill="white"
            onClick={props.closeGraph}
          />
        </span>
      </div>
      <ResponsiveContainer width="100%" height={225}>
        <LineChart
          data={graphData}
          margin={{ top: 0, right: 50, bottom: 0, left: 40 }}
        >
          <XAxis
            dataKey="name"
            tick={false}
            axisLine={{ stroke: 'white', strokeWidth: '2px' }}
          />
          <YAxis
            scale="auto"
            tick={{ fill: 'white' }}
            axisLine={{ stroke: 'white', strokeWidth: '2px' }}
            width={10}
          />
          <Tooltip offset={50} />
          <Legend iconType={'rect'} />
          <CartesianGrid stroke="white" strokeWidth={'1px'} />
          {dataKeys.map((key: string, index: number) => {
            return (
              <Line
                key={key}
                strokeWidth={2}
                unit={' ' + props.pollutant.entity}
                dot={false}
                type="linear"
                dataKey={key}
                stroke={getGraphLineColor(index)}
                yAxisId={0}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
