/// <reference types="react-scripts" />

/**
 * Redux
 */
import { MapState } from '../redux/map/types';
import { SelectionState } from '../redux/selection/types';
import { DataState } from '../redux/data/types';
import { WMSTileLayerProps } from 'react-leaflet';

export interface RootState {
  map: MapState;
  selection: SelectionState;
  data: DataState;
}

/*---------------------------------------------------------------------------*/

// IDEA: a configlayer type, can contain some optional properties,
//   which will be fileld in with defaults at config processing time
//   export interface ConfigLayer {}

// Currently the layer is of type WMS
//   future layers could be of type geojson, shapefiles, ...

export interface MapPosition {
  lat: number;
  lng: number;
  zoom: number;
}

export interface Baselayer {
  key: string;
  title: string;
  description?: string;
  attribution?: string;
  url: string;
  image: string;
  isActive: boolean;
}

export enum APPLICATION_SCOPE {
  concentration = 'concentration',
  emission = 'emission',
}

export interface Configuration {
  baselayers: Baselayer[];
}

// this interface tries to mimick the SLD definition
export interface ColorMap {
  entries: ColorMapEntry[];
}

// this interface tries to mimick the SLD definition
export interface ColorMapEntry {
  color: string;
  quantity: number;
}
export interface Pollutant {
  type: string;
  name: string;
  entity: string;
  colorMap: ColorMap;
}

export interface RawLayer {
  uuid: string;
  rasterName: string;
  scope: string;
  name: string;
  disclaimer: string;
  pollutantType: string;
  period: string;
  sector: string;
  bounds: string;
  wmsLayer: WMSTileLayerProps;

  // only used for layer configuration checks
  _file: string;
}

export interface Layer extends RawLayer {
  isActive: boolean;
}

export interface CrossSection {
  layer: Layer;
  data: number[];
}

export interface AreaEmissions {
  layer: Layer;
  emissionsByKM2: number;
  emissions: number;
}
