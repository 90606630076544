import { LatLngBounds } from 'leaflet';
import * as turf from '@turf/helpers';

export function latLngBoundsToGeometry(
  boundary: LatLngBounds | null
): turf.Feature | null {
  if (boundary === null) return null;
  return turf.polygon([
    [
      [boundary.getNorthWest().lng, boundary.getNorthWest().lat],
      [boundary.getNorthEast().lng, boundary.getNorthEast().lat],
      [boundary.getSouthEast().lng, boundary.getSouthEast().lat],
      [boundary.getSouthWest().lng, boundary.getSouthWest().lat],
      [boundary.getNorthWest().lng, boundary.getNorthWest().lat],
    ],
  ]);
}
